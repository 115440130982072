<template>
    <div class="header-col">
        <span v-if="!params.pivot && params.filter" ref="eMenu" class="menu-filter" aria-hidden="true" style="float: right;">
            <!-- BCT: Added 'close-on-click = false' for unit_amenities, if not, Popup getting close on outside click i.e. closing warning popup -->
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              :close-on-click="params.column.colId !== 'unit_amenities'"
              left
              offset-y
              style="overflow:hidden"
            >
              <template v-slot:activator="{ on }">
                <span v-on="on" v-if="!params.disable_header_filters && params.column_filter">
                     <hb-icon small >mdi-filter-variant</hb-icon>
                </span>
              </template>
                <!-- BCT: Amenity Sticky Filter HB-70 //-->
                <Amenities-sticky-filter 
                  v-if="this.params.column.colId === 'unit_amenities' && menu"
                  v-model="menu"
                  @close="menu = false"
                  :selected-amenitites="filter_value && filter_value.length ? filter_value : []"
                  @applyFilter="setAmenityFilter">
                </Amenities-sticky-filter>
                <!-- BCT: Amenity Sticky Filter HB-70 //-->
                <v-card class="pa-0" v-if="this.params.column.colId !== 'unit_amenities'" >
                  <v-list class="pa-0">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Filter {{ params.displayName }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>

                  <v-list class="px-0 py-0 ma-0"  style="width: 100%; max-height: 200px; overflow-y: scroll;">

                    <v-list-item v-if="params.filter.input === 'multi-select' && $store.getters['filterStore/'+ params.column.colId] && $store.getters['filterStore/'+ params.column.colId].length" dense v-for="(item, i) in $store.getters['filterStore/' + params.column.colId]" :key="`item-${i}`" :value="item">
                        <!--  Filter  -->
                       <v-checkbox
                         dense
                         v-model="filter_value"
                         :value="item.id"
                         :label="item.name"
                         color="primary"
                         hide-details
                       ></v-checkbox>
<!--                        <v-autocomplete-->
<!--                          :items="$store.getters['filterStore/' + params.column.colId]"-->
<!--                          v-model="filter_value"-->
<!--                          item-text="name"-->
<!--                          item-value="id"-->
<!--                          hide-details-->
<!--                          multiple-->
<!--                          dense-->
<!--                          solo-->
<!--                          flat-->
<!--                          clearable-->
<!--                          background-color="white"-->
<!--                          placeholder="Select"-->
<!--                        ></v-autocomplete>-->
                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'multi-select' && params.filter?.options && params.filter?.options?.length" dense v-for="(item, i) in params.filter?.options" :key="`item-${i}`" :value="item">
                      <v-checkbox
                        dense
                        v-model="filter_value"
                        :value="item"
                        :label="item"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                    </v-list-item>

                    <v-list-item v-if="params.filter.input === 'multi-select' && !params.filter?.options?.length && !$store.getters['filterStore/'+ params.column.colId]?.length">
                      No options found
                    </v-list-item>





                    <v-list-item v-if="params.filter.input === 'boolean' && params.filter.options && params.filter.options?.length">
                        <v-checkbox v-model="filter_value" class="mx-3 my-0" hide-details value="0" label="No"></v-checkbox>
                        <v-checkbox v-model="filter_value" class="mx-3 my-0" hide-details value="1" label="Yes"></v-checkbox>
                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'timeframe'">
                        <v-select
                          :items="timeframe_options"
                          v-model="filter_value.label"
                          hide-details
                          dense
                          solo
                          flat
                          clearable
                          @click:clear="handleClear(params.column.colId, 'label')"
                          background-color="white"
                          placeholder="Select"
                        >
                      </v-select>
                    </v-list-item>
                    <v-list-item v-if="filter_value.label && filter_value.label.toLowerCase() === 'custom range'">
                        <hb-date-picker
                          @click:clear="handleClear(params.column.colId, 'start_date')"
                          label="Start Date"
                          :clearable="true"
                          :dense="true"
                          data-vv-as="end date"
                          v-model="filter_value.start_date"></hb-date-picker>
                        <hb-date-picker
                          @click:clear="handleClear(params.column.colId, 'end_date')"
                          label="End Date"
                          :clearable="true"
                          :dense="true"
                          data-vv-as="end date"
                          v-model="filter_value.end_date"></hb-date-picker>
                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'comparison'">
                      <v-select
                        :items="comparison_options"
                        v-model="filter_value.operator"
                        hide-details
                        dense
                        class="ma-0 pa-0"
                        @click:clear="handleClear(params.column.colId, 'operator')"
                        clearable
                        background-color="white"
                        placeholder="Select"
                      ></v-select>
                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'comparison'">
                        <v-text-field
                          v-model.lazy="filter_value.value"
                          :prepend-inner-icon="params.filter.column_type === 'money' ? 'mdi-currency-usd': ''"
                          dense
                          name="from"
                          data-vv-scope="range"
                          :error-messages="errors.collect('range.from')"
                          :data-name="params.column.colId"
                          :id="params.column.colId"
                          background-color="white"
                          :aria-label="params.filter.label"
                          singleLine
                          :label="filter_value.operator && filter_value.operator.toLowerCase() === 'between' ? 'From': 'Value'"
                        >
                        </v-text-field>

                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'comparison' && filter_value.operator && filter_value.operator.toLowerCase() === 'between'">
                        <v-text-field
                          v-model.lazy="filter_value.max"
                          :prepend-inner-icon="params.filter.column_type === 'money' ? 'mdi-currency-usd': ''"
                          dense
                          :data-name="params.column.colId"
                          :id="params.column.colId"
                          background-color="white"
                          :aria-label="params.filter.label"
                          singleLine
                          label="To "
                          name="to"
                          data-vv-scope="range"
                          :error-messages="errors.collect('range.to')"
                        >
                        </v-text-field>

                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'checkbox'">
                      <v-checkbox v-model="filter_value" clearable dense hide-details value="0" :label="params.filter.label"></v-checkbox>
                    </v-list-item>
                    <v-list-item v-if="params.filter.input === 'text'">
                       <v-text-field
                         v-model="filter_value"
                         :label="params.filter.label"
                       ></v-text-field>
                    </v-list-item>
                  </v-list>

                <v-divider></v-divider>
                <v-card class="pa-0">
                  <v-card-actions>
                    <hb-btn v-show="hasFilter" icon small text color="destroy" tooltip="Close" @click="removeFilter">mdi-close</hb-btn>
                    <v-spacer></v-spacer>
                    <a class="mr-2 text-body-2" @click="menu = false">Cancel</a>
                    <hb-btn small color="primary" @click="setFilter">Set Filter</hb-btn>
                  </v-card-actions>
                </v-card>
              </v-card>
              </v-menu>
          </span>

        <div ref="eLabel" class="ag-header-cell-label" role="presentation" unselectable="on">

          <span class="ag-header-cell-text header-checkbox" v-if="params.checkbox" role="columnheader">
              <hb-checkbox v-model="selectAll" @change="updateSelectAll" :class="params.parentComponentName === 'HummingbirdReportViewer' ? 'align-left': ''"></hb-checkbox> 
          </span>
          <span
            v-else
            ref="eText"
            :class="['ag-header-cell-text', { 'active': hasFilter }]"
            role="columnheader"
            unselectable="on"
            aria-colindex="3"
            @click="setSort(hasColumnFilter)"
          >
            {{ valueGetter }}
          </span>
          <span v-if="hasColumnFilter" class="header-sort" @click="setSort()" >
            <hb-icon small v-show="params.column.sort === 'asc'">mdi-chevron-up</hb-icon>
            <hb-icon small v-show="params.column.sort === 'desc'">mdi-chevron-down</hb-icon>
          </span>
        </div>
      </div>
</template>

<script>
    import Vue from 'vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import AmenitiesStickyFilter from '../../assets/spaceManagementComponents/AmenitiesStickyFilter.vue';
    import { EventBus } from '../../../EventBus';
    import { mapActions } from "vuex";

    export default Vue.extend({
      name: "HeaderFilter",
        data() {
            return {
                showAmenityStickyFlter:false,
                selectAll: false,
                selectedAll: false,
                menu:false,
                text: '',
                valueGetter: null,
                filter_value: '',
                boolean_options: [
                    { id: 1,  name: 'Yes'},
                    { id: -1, name: 'No'}
                ],
                timeframe_options: ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Last Month', 'Year To Date', 'Custom Range'],
                comparison_options: ['Greater Than', 'Less Than', 'Equals','Not Equals', 'Greater Than or Equals', 'Less Than or Equals', 'Between']
            };
        },
        components:{
            HbDatePicker,
            AmenitiesStickyFilter
        },
        computed:{
          hasColumnFilter() {
            return this.params?.column_filter ?? true
          },
          // BCT: Added case 'multi-select-amenities'
            hasFilter(){

                if(this.params.pivot || !this.params.filter || !this.params.filter.search) return false;
                switch(this.params.filter.input){
                    case 'text':
                    case 'checkbox':
                    case 'radio':
                    case 'dropdown':
                      return !!this.params.filter.search;
                      break;
                    case 'multi-select-amenities':
                    case 'multi-select':
                    case 'list':
                      return this.params?.filter?.search?.length;
                      break;
                    case 'comparison':
                        return this.params.filter.search.operator  && this.params.filter.search.value
                        break;
                    case 'date':
                    case 'timeframe':
                        return this.params.filter.search.label;
                        break;

                }
            }
        },
        methods: {
          ...mapActions({
                setRestGridEnableAction: 'reportStore/setRestGridEnableAction',
                getBulkContacts : 'reportStore/addBulkContacts'
            }),
            
            updateSelectAll(){   
                this.params.handleSelectAll(this.selectAll);
                if(!this.selectAll && this.selectedAll){
                  this.getBulkContacts([])
                }
            },
            handleCommunicationCount(data){
              this.selectAll = data.selectedAll
              if(data.selectedAll){
                EventBus.$emit('handle_select_all', true);
              }
              else{
                EventBus.$emit('handle_select_all', false);
              }
              this.selectedAll=data.selectedAll;
            },
            handleClear(field, input){
                //this.form[field][input] = '';
            },
            isFilterActive() {
                return this.text !== null && this.text !== undefined && this.text !== '';
            },

            doesFilterPass(params) {

            },

            getModel() {
                return { value: this.text };
            },

            setModel(model) {
                if (model) {
                    this.text = model.value;
                }
            },

            afterGuiAttached() {
                this.$refs.input.focus();
            },

            setSort(sortValue = true){
              if(sortValue) {
                EventBus.$emit("reset_grid_count_values");
                this.setRestGridEnableAction(true);
                this.params.progressSort(false);
                this.params.api.refreshHeader();
              }
            },
            cancel(){

            },
            async removeFilter(){
               switch(this.params.filter.input){
                case null:
                case 'text':
                case 'radio':
                case 'dropdown':
                    this.filter_value = '';
                    break;
                case 'multi-select':
                case 'list':
                    this.filter_value = [];
                    break;
                case 'checkbox':
                    this.filter_value = false;
                    break;
                case 'comparison':
                    this.filter_value = {
                        operator: '',
                        value: '',
                        max: ''
                    };
                    break;
                case 'date':
                case 'timeframe':
                    this.filter_value = {
                        label: '',
                        days: 0,
                        period: '',
                        relation: '',
                        start_date: '',
                        end_date: ''
                    };
                    break;
                }
                await this.setFilter();
            },
            // BCT: Amenity Sticky Filter HB-70
            setAmenityFilter(data) {
             this.filter_value = data;
             this.setFilter();
            },

            async setFilter(){
                // var filterInstance = this.params.api.getFilterInstance(this.params.column.colId);
                // filterInstance.setModel(this.filter_value);
                  let status= await this.$validator.validateAll('range')
                  if(status){
                    this.$store.commit('reportStore/setColumnFilter', {
                      report_id: this.params.report_id,
                      report_template: this.params.report_template,
                      key: this.params.column.colId,
                      search: this.filter_value
                    }); // load from cache

                    if(!this.params.api) return;
                    // this.params.api.purgeServerSideCache();
                    this.params.api.onFilterChanged();
                  }

            },

            async loadFilterData(){


                if( this.params.column.colId === 'property_id') await this.$store.dispatch('filterStore/getProperties');
                if( this.params.column.colId === 'unit_floor') await this.$store.dispatch('filterStore/getFloors');
                if( this.params.column.colId === 'unit_size') await this.$store.dispatch('filterStore/getSizes');
                if( this.params.column.colId === 'unit_category') await this.$store.dispatch('filterStore/getCategories');

                if( this.params.column.colId === 'rent_change_deployment_month') await this.$store.dispatch('filterStore/getDeploymentMonths')

                if( this.params.column.colId === 'lead_property') await this.$store.dispatch('filterStore/getProperties');
                //if( this.params.column.colId === 'product_id') await this.$store.dispatch('filterStore/getProducts');
                if( this.params.column.colId === 'lead_source') await this.$store.dispatch('filterStore/getLeadSources');
                if( this.params.column.colId === 'tenant_last_contacted_by') await this.$store.dispatch('filterStore/getAdmins');

               // if( this.params.column.colId === 'lease_sign_up_promo_id') await this.$store.dispatch('filterStore/getPromotions');

                // if( this.params.column.colId === 'lease_last_payment_source') await this.$store.dispatch('filterStore/getPaymentSources');
                // if( this.params.column.colId === 'trigger_event_type') await this.$store.dispatch('filterStore/getEventTypes');
                if(['lease_standing', 'tenant_lease_standing'].includes(this.params.column.colId))  await this.$store.dispatch('filterStore/getLeaseStandings');

                // Should be in settings
                if( this.params.column.colId === 'tenant_last_contacted_method') await this.$store.dispatch('filterStore/getContactMethods');
                // if( this.params.column.colId === 'maintenance_type') await this.$store.dispatch('filterStore/getMaintenanceTypes');

                // if( this.params.column.colId === 'method_type') await this.$store.dispatch('filterStore/getPaymentMethodTypes');
                // if( this.params.column.colId === 'product_type') await this.$store.dispatch('filterStore/getProductTypes');

                // if( this.params.column.colId === 'payment_status') await this.$store.dispatch('filterStore/getPaymentStatuses');
                // if( this.params.column.colId === 'reservation_unit_id') await this.$store.dispatch('filterStore/getUnits');



                // if( this.params.column.colId === 'application_status') await this.$store.dispatch('filterStore/getApplicationStatuses');
                if( this.params.column.colId === 'lead_last_contacted_by') await this.$store.dispatch('filterStore/getAdmins');
                // if( this.params.column.colId === 'lead_last_contacted_method') await this.$store.dispatch('filterStore/getContactMethods');
                // if( this.params.column.colId === 'payment_source') await this.$store.dispatch('filterStore/getPaymentSources');
                if( this.params.column.colId === 'reservation_property_id') await this.$store.dispatch('filterStore/getProperties');
                if( this.params.column.colId === 'reservation_category_id') await this.$store.dispatch('filterStore/getCategories');
                if( this.params.column.colId === 'lead_property_id') await this.$store.dispatch('filterStore/getProperties');
                if( this.params.column.colId === 'lead_category') await this.$store.dispatch('filterStore/getCategories');
               // if( this.params.column.colId === 'lead_unit_id') await this.$store.dispatch('filterStore/getUnits');
                if( this.params.column.colId === 'task_created_by') await this.$store.dispatch('filterStore/getAdmins');
                if( this.params.column.colId === 'task_assigned_to') await this.$store.dispatch('filterStore/getAdmins');
                if(this.params.column.colId === 'unit_amenities')  await this.$store.dispatch('filterStore/getUnitAmenities');
                // if( this.params.column.colId === 'trigger_fee_product') await this.$store.dispatch('filterStore/getProducts');

                //if( this.params.column.colId === 'trigger_fee_type') await this.$store.dispatch('filterStore/getProductTypes');
                //if( this.params.column.colId === 'trigger_lease_status_update') await this.$store.dispatch('filterStore/getLeaseStatuses');
                if(this.params.column.colId === 'lead_converted_to_rental_by')  await this.$store.dispatch('filterStore/getLeadConvertedToRentalBy');
                if(this.params.column.colId === 'lead_converted_to_reservation_by')  await this.$store.dispatch('filterStore/getLeadConvertedToReservedBy');
                if(this.params.column.colId === 'lead_created_by_name') await this.$store.dispatch('filterStore/getLeadCreatedByName');
                if(this.params.column.colId === 'lead_unit_interest_type')  await this.$store.dispatch('filterStore/getUnitTypes');
            }


        },
        watch: {
            text: function(val, oldVal) {

            },
            filter_value: {
              deep: true,
              //immediate: true,
              handler: _.debounce(function(filters){
               //console.log("filtass-----", this.filter_value);
              })
            },
        },
        created() {
          if(!this.params.pivot && this.params.filter && this.params.filter.search){
            this.filter_value = JSON.parse(JSON.stringify(this.params.filter.search));
          }
          this.valueGetter = this.params.displayName;
          this.loadFilterData()
          EventBus.$on("communicationCount", this.handleCommunicationCount);
        },
        beforeDestroy() {
          // Clean up the event listener to prevent memory leaks
          EventBus.$off("communicationCount", this.handleCommunicationCount);
        }
    });
</script>

<style lang="scss" scoped>
  .header-col{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .header-sort{
    float: right;
  }

  .header-value{

  }
  .menu-filter{
    opacity: 0;
    transition: opacity 0.2s ease 0s, border 0.2s ease 0s;
  }

  .header-col:hover .menu-filter{
    opacity: 1;
  }

  .active{
    color: #00848E;
    font-weight: 500;
  }

  .header-checkbox {
    ::v-deep .v-input--selection-controls__ripple {
      width: 0px !important;
    }
  }

  .align-left {
    position: relative;
    left: -4px;
  }

</style>
